.moving-text {
  position: absolute;
  bottom: 70px;
  left: 100vw; /* Start fully off-screen to the right */
  white-space: nowrap;
  font-size: 15rem;
  color: white;
  /* background-image: url("../images/smoke2.gif");
  background-clip: text;
  background-size: 200%;
  -webkit-text-fill-color: transparent; */
  z-index: 9;

  /* Updated animation */
  animation: moveText 15s linear infinite;
}

@keyframes moveText {
  0% {
    transform: translateX(30vw); /* Start fully off-screen to the right */
  }
  100% {
    transform: translateX(-350vw); /* Move fully off-screen to the left */
  }
}
