/* Header */

body,
html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.popup {
  /* position:absolute;
  left:50%;
  top:50%;
  width:30%;
  height:50%; */
  color: white;
}

.logo-container {
  height: auto;
  /* background-color: #808080; */
  min-width: -webkit-fill-available;
  padding: 5px;
  border-color: #808080;
  opacity: 1;
}
.logo-container-c {
  position: absolute; /* Or fixed if you want it to stay centered on scroll */
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%); /* Centers the element exactly */
  min-width: -webkit-fill-available; /* Makes width responsive to screen */
  opacity: 1;
  border-color: #808080;
  height: auto;
}

.header {
  min-height: 200px;
  /* background-color: #808080; */
  /* min-width: -webkit-fill-available; */
  width: 40%;
  /* border-style: solid; */
  margin: 10px;
  margin-top: 0px;
  border-radius: 5px;
  border-width: 0.5px;
  border-color: #808080;
  padding: 15px;
  display: inline-flex;
  opacity: 1;
}
.header-nav {
  height: 30px;
  /* background-color: #808080; */
  border-style: solid;
  margin: 10px;
  margin-top: 0px;

  border-radius: 5px;
  border-width: 0.5px;
  border-color: #808080;
  padding: 15px;
  opacity: 1;
}

/* Header  End */

p {
  margin-top: 0px;
}

* {
  padding: none;
  /* margin: auto; */
  box-sizing: border-box;
}

.background-w {
  background-color: white;
}

.background-b {
  background-color: red;
}

.video-container {
  position: fixed;
}

.form {
  position: fixed;
  bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  color: white;
  /* height: 100px; */
}

.footer {
  height: auto;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: black;
}

.container-1 {
  padding: 10px;
  margin: auto;
  width: 25vw;
  text-align: center;
}

video {
  margin: 30px;
  width: 500px;
  max-width: 85%;
}

.background-container {
  columns: 2;
  width: 100vw;
  height: 100vh;
}

/* .box1{
  background-image:url('')
}

.box2{
  background-image: url('');
} */

@font-face {
  font-family: Bebas;
  src: url(./Fonts/BebasNeue-Regular.ttf);
  font-weight: regular;
}

@font-face {
  font-family: Righteous;
  src: url(./Fonts/Righteous-Regular.ttf);
  font-weight: regular;
}

@font-face {
  font-family: FragmentMono;
  src: url(./Fonts/FragmentMono-Regular.ttf);
  font-weight: regular;
}

@font-face {
  font-family: FragmentMono-i;
  src: url(./Fonts/FragmentMono-Italic.ttf);
  font-weight: italic;
}

@keyframes bganimation {
  0% {
    background-position: -100% -100%, 200% 200%, -100% 200%, 200% -100%;
  }

  50% {
    background-position: 150% 100%, -200% 100%, 100% 0%, 0% 100%;
  }

  100% {
    background-position: -100% -100%, 200% 200%, -100% 200%, 200% -100%;
  }
}

.splash-page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: black;
  z-index: 11;
  opacity: 1; /* Start fully visible */
  animation: fadeOut 0.5s 1s forwards; /* Fade out over 1 second, adjust as needed */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  } /* Start at full opacity */
  100% {
    opacity: 0;
  } /* End at zero opacity */
}

.splash-page.fade-out {
  display: none; /* Hide the element completely after fade-out */
}

.typing {
  /* width: 30%; */
  position: relative;
  font-size: 1rem;
  text-align: left;
  position: relative;
  z-index: 2;
  color: white;
  padding-bottom: 10px;
  /* background-image: url("./images/smoke.gif");
  background-clip: text;
  background-size: 400%;
  -webkit-text-fill-color: transparent; */
}

.app {
  max-width: 100vw;
  min-height: 100vh;
  /* background: radial-gradient(
      ellipse at center,
      rgba(233, 233, 233, 0.3) 30%,
      rgba(226, 5, 5, 0) 60%
    ),
    radial-gradient(
        ellipse at center,
        rgba(255, 255, 255, 0.94) 10%,
        rgba(0, 0, 0, 0) 60%
      )
      #000000; */
  background: #000000;
  /* background: #ffffff; */

  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 600px 600px, 600px 600px, 600px 600px;
  background-attachment: fixed;
  animation: bganimation 30s infinite;
  /* justify-content: center; */
  /* align-items: center; */
}

.typewriter {
  color: rgb(191, 191, 191);
  position: relative;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-family: Bebas;
  /* font-family: Righteous; */
  font-family: FragmentMono;
}

.app--logo-wrapper {
  width: 20px;
  text-align: center;
}

.app--logo-image {
  object-fit: contain;
  width: 300px;
  vertical-align: middle;
  margin: auto;
  opacity: 1;
  transition: 0.3s;
}

.app--logo-image:hover {
  opacity: 1;
  cursor: pointer;
}

.time-date {
  color: white;
  bottom: 0px;
  right: 10px;
  font-size: 5rem;
  position: relative;
  width: auto;
  float: right;
  z-index: 10;
  /* background-image: url("./images/smoke2.gif");
  background-clip: text;
  background-size: 100%;
  -webkit-text-fill-color: transparent; */
}

.app--content {
  text-align: center;
  width: 50vw;
  margin: auto;
}

.hidden {
  display: none;
}

.form {
  display: block;
  width: auto;
  justify-content: center;
  text-align: left;
  bottom: 20px;
  left: 20px;
  z-index: 3;
}

.field {
  width: 150px;
  height: 30px;
  padding-left: 3px;
  float: left;
  color: white;
  text-align: left;
  background-color: rgba(250, 250, 250, 0.093);
  border: 0 solid #000;
  border-radius: 0;
  margin-bottom: 0;
  margin-right: 5px;
  font-family: Inconsolata, monospace;
  font-size: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.31),
    inset 0 2px 9px -1px rgba(0, 0, 0, 0.14),
    0 0 0 1px rgba(255, 255, 255, 0.09), 0 1px rgba(255, 255, 255, 0.13);
}

.field:focus {
  outline: none;
  border: 1.5px solid #555;
}

.button {
  height: 30px;
  float: left;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.19);
  background-color: #20202047;
  /* border-radius: 20px; */
  padding: 4px 29px;
  font-family: Inconsolata, monospace;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: background-color 0.3s;
  display: block;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.11),
    inset 0 0 0 1px rgba(255, 255, 255, 0.08),
    inset 0 1px rgba(255, 255, 255, 0.16),
    inset 0 -44px 38px -25px rgba(0, 0, 0, 0.09);
}

.button:hover {
  cursor: pointer;
}

.paragraph {
  font-family: Inconsolata, monospace;
  font-size: 14px;
  position: static;
  letter-spacing: 0;
  text-shadow: none;
  margin-top: 0;
  margin-bottom: 5px;
  color: #fff;
}

@media screen and (max-width: 900px) {
  .app--logo-image {
    width: 600px;
  }
  .form {
    display: none;
    left: 0px;
  }

  .moving-text {
    display: none;
  }

  @media screen and (max-width: 700px) {
    .app--logo-image {
      width: 80vw;
      margin-bottom: 200px;
    }

    .typing {
      width: 80%;
      font-size: 1rem;
    }
    .form {
      flex-wrap: wrap;
      /* width: 80%; */
      margin: auto;
      width: -webkit-fill-available;
      display: none;
      margin-bottom: 50px;
      left: 0px;
    }

    .field {
      margin: 0px;
      margin-bottom: 15px;
      width: 50vw;
      left: 0px;
    }

    .button {
      width: 100%;
    }

    .header {
      width: 100%;
      margin: 0px;
      padding: 10px;
    }
  }

  @media screen and (max-width: 700px) {
    .typing {
      height: auto;
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 430px) {
    .app--content {
      margin: unset;
    }

    .app--logo-image {
      margin-bottom: 40px;
    }
  }
}
